interface Address {
  address: "";
  city: "";
  zip: "";
  country: "";
  info: "";
}
export class BuyerGenericDTO {
  // USE FOR DYNAMIC
  [index: string]: string | Address | number | boolean;

  public id = 0;
  public orgaId = 0;
  public sameFamily = "";
  public userWpId = 0;
  public oldStudentId = 0;
  public date = "";
  public dateModified = "";

  // ABOUT BUYER
  public firstName = "";
  public lastName = "";
  public email = "";
  public phone = " ";
  public signature = "";

  // ADRESSE
  public buyerAddress: Address = {
    address: "",
    city: "",
    country: "",
    info: "",
    zip: "",
  };
  public buyerAddressDistrict = "";

  // META
  public askInvoice = "";
  public askNewsletter = "";
  public schoolFindOut = "";
  public comment = "";

  constructor(init?: Partial<BuyerGenericDTO>) {
    if (init === undefined) return;

    Object.assign(this, init);
  }
}
